import useTheme from "@/js/composables/useTheme";
import useRegister from "@/js/composables/useRegister";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { Customer } from "@/graphql/generated/graphql-operations";
import { parseGid } from "@/js/utils/shopify-gid";

export default () => {
  const { customer: shopifyCustomer, IS_STAGING } = useTheme();
  const register = useRegister();

  const analytics = AnalyticsBrowser.load({
    writeKey: IS_STAGING
      ? "ARIhMqpHNDHiHPIFDuECggr5cXKWgesP"
      : "8ngX7xcZEeSwaqCHiazjnr5VxYHR2tXx",
  });

  const segmentIdentify = (storefrontCustomer: Customer | null = null) => {
    const customer = shopifyCustomer || storefrontCustomer || null;
    const customerId = storefrontCustomer?.id
      ? parseGid(storefrontCustomer.id)
      : shopifyCustomer?.id
        ? shopifyCustomer.id
        : null;

    const traits = {
      ...(customer?.email && {
        email: customer?.email || null,
      }),
      ...{
        evolv_uid: localStorage.getItem("evolv:uid"),
      },
      ...((register?.value?.customer_address?.province ||
        (Array.isArray(customer?.addresses) &&
          customer?.addresses[0]?.province) ||
        (Array.isArray(customer?.addresses) &&
          customer?.addresses[0]?.address1)) && {
        default_address: {
          ...((register?.value?.customer_address?.province ||
            (Array.isArray(customer?.addresses) &&
              customer?.addresses[0]?.province)) && {
            state:
              register?.value?.customer_address?.province ||
              (Array.isArray(customer?.addresses) &&
                customer?.addresses[0]?.province) ||
              null,
          }),
          ...(Array.isArray(customer?.addresses) &&
            customer?.addresses[0]?.address1 && {
              street: customer?.addresses[0]?.address1 || null,
            }),
        },
      }),
      ...(customer?.phone && {
        phone: customer?.phone || null,
      }),
      ...(register?.value && {
        ...(register?.value?.pet_name && {
          petname: register?.value?.pet_name || null,
        }),
        ...(register?.value?.pet_type && {
          pettype: register?.value?.pet_type || null,
        }),
        ...(register?.value?.customer_address?.province && {
          pethomestate: register?.value?.customer_address?.province || null,
        }),
      }),
    };

    analytics.identify(customerId as any, traits, {
      ...(analytics.instance?.user().anonymousId() && {
        anonymousId: analytics.instance?.user().anonymousId() as any,
        evolv_uid: localStorage.getItem("evolv:uid"),
      }),
    });
  };

  const segmentTrack = (event: string, properties?: any) => {
    analytics.track(event, properties);
  };

  const segmentPage = (name: string) => {
    analytics.page(name, {
      url: location.href,
    });
  };

  return {
    segmentIdentify,
    segmentTrack,
    segmentPage,
  };
};
